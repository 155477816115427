import { Plural, Trans } from '@lingui/macro'
import classNames from 'classnames'
import { Fragment, useState, useCallback, useRef } from 'react'

import styles from './EditGiftsModal.module.scss'
import { useUpdateCart } from './useUpdateCart'
import CartGiftItem from '../cart/CartGiftItem'
import { ConfigurableAttributesFieldValue } from '../catalog/ProductPage/ConfigurableAttributesField/ConfigurableAttributesField'
import { ConfigurableProduct } from '../catalog/ProductPage/ConfigurableProduct'
import { Product } from '../catalog/ProductPage/GetProduct.query'
import Button from '../input/Button'
import Loader from '../presentation/Loader'
import ResponsiveModal, {
    Props as ResponsiveModalProps,
} from '../presentation/ResponsiveModal/ResponsiveModal'
import { GiftRule, GiftRuleProduct } from '../reducers/cart'
import { useSelector } from '../types'
import Heading from '../typography/Heading'

interface OwnProps {
    rules: GiftRule[]
}

type Props = OwnProps & Pick<ResponsiveModalProps, 'onBack'>

const isEmptyOptionValue = (option: Record<number | string, number>) =>
    !Object.keys(option).length

type SelectedProductsMap = {
    [key: number | string]: {
        options: ConfigurableAttributesFieldValue | undefined
        product: ConfigurableProduct | Product
    }
}

const EditGiftsModal = ({ rules, onBack }: Props) => {
    const { loading, updateCart } = useUpdateCart()

    const selectedProductsRef = useRef<SelectedProductsMap>({})

    // Besides the rules visible in this modal we also need the actual rules
    // in order to get live feedback from our updates
    const freegifts = useSelector(
        (state) => state.cart?.details?.extension_attributes?.free_gifts,
    )

    const onBackCallback = useCallback(async () => {
        const productIds = Object.keys(selectedProductsRef.current)
        let selectedOptions: Array<{
            product: ConfigurableProduct | Product
            options: ConfigurableAttributesFieldValue | undefined
        }> = []
        for (const prodId of productIds) {
            const { options, product } = selectedProductsRef.current[prodId]
            selectedOptions = [
                ...selectedOptions,
                {
                    product,
                    options,
                },
            ]
        }
        await updateCart(selectedOptions)
        onBack?.()
    }, [onBack, updateCart])

    // Get list of gift rule id's that are visible in the modal
    const giftRuleIds = rules.map((r) => r.rule_id)

    // Filter on visible rules and calculate some values
    const { maxItems } = (freegifts || []).reduce(
        (acc, gift) => {
            if (giftRuleIds.includes(gift.rule_id)) {
                acc.selectedCount =
                    acc.selectedCount +
                    (gift.item_ids ? gift.item_ids.length : 0)
                acc.maxItems = acc.maxItems + (gift.max_items || 0)
            }
            return acc
        },
        { selectedCount: 0, maxItems: 0 },
    )

    const RenderRule = (rule: GiftRule) => {
        const { products } = rule

        const [selectedProductsMap, setSelectedProductsMap] = useState<{
            map: Map<number, Record<number | string, number> | undefined>
        }>({ map: new Map() })

        const toggleSelection = (
            product: GiftRuleProduct,
            option: Record<number | string, number>,
            confProduct: ConfigurableProduct | Product,
        ) => {
            const productsMap = selectedProductsMap.map

            productsMap.set(
                product.id,
                isEmptyOptionValue(option) ? undefined : option,
            )
            setSelectedProductsMap({ map: productsMap })
            selectedProductsRef.current = {
                ...selectedProductsRef.current,
                [product.id]: {
                    options: option as ConfigurableAttributesFieldValue,
                    product: { ...confProduct },
                },
            }
        }

        const isProductSelected = (product: GiftRuleProduct) => {
            const option = selectedProductsMap.map.get(product.id)
            return !option ? false : !isEmptyOptionValue(option)
        }

        const selectedCount = Array.from(selectedProductsMap.map).filter(
            ([, value]) => !isEmptyOptionValue(value ?? {}),
        ).length

        return (
            <div className={styles.group} key={`${rule.rule_id}`}>
                <div className={classNames(styles.subheader, styles.sticky)}>
                    <span>
                        <Plural
                            id="cart.gifts.modalCongratsHeader"
                            value={maxItems}
                            one="Congrats! Select your exclusive gift"
                            other="Congrats! Select your {maxItems} exclusive gifts"
                        />
                    </span>
                </div>
                {products.map((p) => {
                    const isSelectedProduct = isProductSelected(p)

                    return (
                        <Fragment key={`${p.id}-${rule.rule_id}`}>
                            <div
                                className={classNames(
                                    styles.gift,
                                    isSelectedProduct ? styles.selected : '',
                                )}
                            >
                                <CartGiftItem
                                    editMode
                                    hideAmount
                                    hideFreeGiftLabel
                                    isSelectionDisabled={
                                        selectedCount >= maxItems &&
                                        !isSelectedProduct
                                    }
                                    className={styles.padding}
                                    onChange={(option, product) => {
                                        toggleSelection(
                                            p,
                                            option as Record<number, number>,
                                            product,
                                        )
                                    }}
                                    productId={p.id}
                                    cartItem={p.item}
                                    rule={{ ...rule, items: [] }}
                                />
                            </div>
                        </Fragment>
                    )
                })}
            </div>
        )
    }

    const selectedCount = Object.keys(selectedProductsRef.current || {}).reduce(
        (counter, key) => {
            const option = selectedProductsRef.current[key].options as Record<
                number | string,
                number
            >
            if (!isEmptyOptionValue(option)) {
                return counter + 1
            }
            return counter
        },
        0,
    )

    return (
        <ResponsiveModal
            onBack={onBack}
            header={
                <Heading variant="h2" element="h1">
                    <Plural
                        id="cart.gifts.modalSelectGiftHeading"
                        value={maxItems}
                        one="Select your gift"
                        other="Select your gifts"
                    />
                </Heading>
            }
            footer={
                <>
                    <div
                        className={classNames(styles.subheader, styles.bottom)}
                    >
                        <span>
                            <Trans id="cart.gifts.modalSelectedItemsCount">
                                {selectedCount} of {maxItems} gifts selected
                            </Trans>
                        </span>
                    </div>
                    <Button
                        name="closeFreeGiftsModal"
                        category="cart.gifts.modal"
                        onClick={onBackCallback}
                        wide
                        disabled={loading || selectedCount < maxItems}
                        variant="primary"
                    >
                        {loading && <Loader className={styles.loader} />}
                        <Trans id="cart.gifts.modal.submitLabel">
                            Save changes
                        </Trans>
                    </Button>
                </>
            }
        >
            {rules?.map(RenderRule)}
            {loading && (
                <div className={styles.saveGifts}>
                    <Loader className={styles.loader} />
                </div>
            )}
        </ResponsiveModal>
    )
}

export default EditGiftsModal
