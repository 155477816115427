import { t } from '@lingui/macro'
import { useLayoutEffect, useRef, useState } from 'react'

import { stripMaybes } from '@emico/utils'

import { Footer } from './Footer'
import { GuidedSellingResults } from './GuidedSellingResults'
import { Header } from './Header'
import { Question } from './Question'
import styles from './TweakwiseConfigurator.module.scss'
import { useTweakwiseFunnelState } from './useTweakwiseFunnelState'
import Button from '../input/Button'

interface Props {
    tweakwiseFunnelId: string
}

type GivenAnswers = string[]

export type QuestionType = 'default' | 'color' | 'jeans_size_combined'

const questionTypes: QuestionType[] = [
    'default',
    'color',
    'jeans_size_combined',
]

export type QuestionDefinition = {
    questionType: QuestionType
    questionLabel: string
}

export const parseQuestion = (questionString: string): QuestionDefinition => {
    const [type, question] = questionString.split('|')

    if (questionTypes.includes(type as QuestionType)) {
        return { questionType: type as QuestionType, questionLabel: question }
    }

    return {
        questionType: 'default',
        questionLabel: type,
    }
}

export const TweakwiseConfigurator = ({ tweakwiseFunnelId }: Props) => {
    const [hasAnswersAndSkippable, setHasAnswersAndSkippable] =
        useState<boolean>()

    const {
        back,
        addAnswer,
        nextQuestion,
        loading,
        isFirstQuestion,
        showResults,
        products,
        navigationUrl,
        cid,
        count,
    } = useTweakwiseFunnelState(tweakwiseFunnelId)
    const { id: questionId } = nextQuestion || {}
    const answersRef = useRef<GivenAnswers>([])

    const [selectedCount, setSelectedCount] = useState<number>()

    const { skippable, skipLabel } = nextQuestion ?? {}

    useLayoutEffect(() => {
        answersRef.current = []
        if (!loading) {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }, 0)
        }
    }, [questionId, loading])

    const hasAnswersSelected = nextQuestion?.answers?.some((a) => a?.isSelected)

    const { questionType, questionLabel } = parseQuestion(
        nextQuestion?.question ?? '',
    )

    return (
        <div className={styles.container}>
            <Header
                onBack={!isFirstQuestion ? back : undefined}
                title={showResults ? `${count} results` : questionLabel}
                subTitle={showResults ? '' : nextQuestion?.explanation ?? ''}
            />

            {showResults && cid ? (
                <GuidedSellingResults
                    link={navigationUrl!}
                    items={products?.filter(stripMaybes) ?? []}
                    cid={cid}
                />
            ) : (
                nextQuestion && (
                    <>
                        <Question
                            question={{
                                ...nextQuestion,
                                questionType,
                                questionLabel,
                            }}
                            onSelectionChange={(questionId, answerIds) => {
                                if (questionId) {
                                    if (skippable) {
                                        setHasAnswersAndSkippable(
                                            Boolean(answerIds.length),
                                        )
                                    }
                                    if (answersRef.current) {
                                        answersRef.current = answerIds
                                    }

                                    // Based on selected answer-id(s), get the number of results:
                                    const numFound =
                                        nextQuestion.answers?.reduce<number>(
                                            (prev, curr) => {
                                                if (
                                                    answersRef.current.includes(
                                                        curr?.answerId ?? '',
                                                    )
                                                ) {
                                                    return (
                                                        prev +
                                                        (curr?.count ?? 0)
                                                    )
                                                }
                                                return prev
                                            },
                                            0,
                                        )
                                    setSelectedCount(numFound || count)
                                }
                            }}
                        />

                        <Footer
                            numberOfResults={selectedCount ?? count}
                            dark={Boolean(
                                answersRef.current.length ||
                                    (hasAnswersSelected &&
                                        hasAnswersAndSkippable),
                            )}
                            button={
                                <Button
                                    variant={
                                        skippable && !hasAnswersAndSkippable
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                    name=""
                                    category=""
                                    className={styles.submitButton}
                                    onClick={() => {
                                        if (
                                            !skippable &&
                                            !answersRef.current.length
                                        ) {
                                            return
                                        }
                                        addAnswer(
                                            nextQuestion?.id ?? '',
                                            answersRef.current ?? [],
                                        )
                                    }}
                                    loading={loading}
                                >
                                    {skippable && !hasAnswersAndSkippable
                                        ? skipLabel
                                        : t({
                                              id: 'guidedSelling.continue',
                                              message: 'Continue',
                                          })}
                                </Button>
                            }
                        />
                    </>
                )
            )}
        </div>
    )
}
