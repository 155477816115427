import { Image } from '@emico-react/image'
import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { CheckIcon } from '@emico/icons'

import optionStyles from './Option.module.scss'
import { MAX_COLS, OptionsType } from './Options'
import styles from './Options.module.scss'
import Heading from '../../typography/Heading'
import Text from '../../typography/Text'

export const DefaultOptions = ({ answers, isMultiSelect }: OptionsType) => {
    const { register } = useFormContext()
    const gridRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // desktop grid cols depends on the number of answers:
        // mobile: 2 cols always
        // desktop:
        const divider = Math.min(
            answers?.length ?? 0,
            MAX_COLS,
            answers?.length ?? 0 % MAX_COLS,
        )

        gridRef.current?.style.setProperty(
            '--num-cols',
            `${Math.max(divider, MAX_COLS / (divider || 1))}`,
        )
    }, [answers?.length])

    if (!answers) {
        return null
    }
    return (
        <div className={styles.grid} ref={gridRef}>
            {answers?.map((answer) => (
                <label key={answer.answerId} className={optionStyles.option}>
                    <span className={optionStyles.selectedIndicator}>
                        <CheckIcon />
                    </span>
                    <Image
                        url={answer.imageUrl ?? ''}
                        className={optionStyles.answerImage}
                        width={250}
                        lazy
                        alt={answer.answer ?? ''}
                    />
                    <input
                        {...register?.('question')}
                        value={answer.answerId}
                        type={isMultiSelect ? 'checkbox' : 'radio'}
                    />
                    <div className={optionStyles.answerHeader}>
                        <Heading color="light" variant="h3" element="h3">
                            {answer?.answer}
                        </Heading>
                        <Text
                            className={optionStyles.answerCaption}
                            color="light"
                            size="small"
                        >
                            {answer?.caption}
                        </Text>
                    </div>
                </label>
            ))}
        </div>
    )
}
