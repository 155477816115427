import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Item } from '@react-stately/collections'
import { useEffect, useMemo, useState } from 'react'

import { InfoCircleIcon } from '@emico/icons'
import { useActiveStoreView } from '@emico/storeviews'
import { minWidth } from '@emico/styles'
import { Icon, Separator, useBreakpoints } from '@emico/ui'

import PopperSelect from '../../../input/PopperSelect'
import ResponsiveModal from '../../../presentation/ResponsiveModal/ResponsiveModal'
import ResultDetailOverlayModal from '../../../storelocator/ResultDetailOverlayModal'
import SearchForm from '../../../storelocator/SearchForm'
import StoreMap, { Location } from '../../../storelocator/StoreMap'
import { SrsStore } from '../../../storelocator/useSrsStore'
import { LocationProps, useSrsStores } from '../../../storelocator/useSrsStores'
import theme from '../../../theme'
import Heading from '../../../typography/Heading'
import { Product } from '../GetProduct.query'
import defaultAttributes from '../StickyCta/defaultAttributes'
import flattenCombinedAttributes, {
    lengthLabel,
    widthLabel,
} from '../StickyCta/flattenCombinedAttributes'

const contentPadding = css`
    @media ${minWidth('lg')} {
        padding-left: 26px;
        padding-right: 26px;
    }
`

const HeadingWrapper = styled.div`
    @media ${minWidth('lg')} {
        ${contentPadding}
        padding-top: 26px;
        padding-bottom: 12px;
    }
`

const Col = styled.div`
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;
    @media ${minWidth('lg')} {
        width: 45%;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }
`

const WrapperMap = styled.div`
    position: relative;
    display: none;

    @media ${minWidth('lg')} {
        position: absolute;
        display: block;
        padding: 0;
        top: 0;
        right: 0;
        width: 55%;
        height: 100%;
    }
`

const SubHeading = styled(Heading)`
    margin-top: 10px;
`

const StoresWrapper = styled.div<{ visible: boolean }>`
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

    @media ${minWidth('lg')} {
        ${contentPadding}
    }
`

const StyledPopperSelect = styled(PopperSelect)`
    width: 100%;
    margin-top: 10px;

    @media ${minWidth('lg')} {
        max-width: 450px;
    }
` as typeof PopperSelect

const StyledSearchForm = styled(SearchForm)`
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;

    @media ${minWidth('lg')} {
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        max-width: none;
    }
`

const CtaBlock = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: ${theme.colors.white};

    @media ${minWidth('lg')} {
        margin-top: auto;
        padding: 20px;
    }
`

const StyledIcon = styled(Icon)`
    flex: 0 0 auto;
`

interface Props {
    onClose: () => void
    product: Product
    defaultKey?: string
}

const ShopSupplyModal = ({ onClose, product, defaultKey }: Props) => {
    const { isDesktop } = useBreakpoints()
    const [sizeSku, setSizeSku] = useState<string | undefined>()
    const [selectedStore, setSelectedStore] = useState<SrsStore>()

    const [currentLocation, setCurrentLocation] = useState<LocationProps>()
    const [selectedStoreLocation, setSelectedStoreLocation] =
        useState<LocationProps>()

    const activeStore = useActiveStoreView()
    let storeView = ''

    if (activeStore.code.includes('castiron')) {
        storeView = 'castiron'
    } else if (activeStore.code.includes('pme')) {
        storeView = 'pme'
    } else if (activeStore.code.includes('vanguard')) {
        storeView = 'vanguard'
    } else if (activeStore.code.includes('justbrands')) {
        storeView = 'justbrands'
    }

    const flattenedOptions = flattenCombinedAttributes(
        product,
        undefined,
        undefined,
        ({ a, b }) => [widthLabel(a), lengthLabel(b)].filter(Boolean).join(' '),
    )
    const defaultOptions = defaultAttributes(product, ({ label }) => label)
    const options =
        (flattenedOptions ?? defaultOptions)?.map((o) => ({
            key: o.value,
            label: o.label,
            sku: o.sku,
            isDisabled: o.disabled,
        })) ?? []

    useEffect(() => {
        const value = options.find((o) => o.key === defaultKey)

        setSizeSku(value?.sku)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultKey])

    const location = selectedStoreLocation
        ? selectedStoreLocation
        : currentLocation
          ? currentLocation
          : undefined

    const { data: storesWithStock, loading } = useSrsStores({
        coordinates: location,
        pageSize: 200,
        filters: {
            sku: sizeSku,
            pme_store: storeView === 'pme',
            jb_store: storeView === 'justbrands',
            ci_store: storeView === 'castiron',
        },
        options: {
            skip: sizeSku === undefined,
        },
    })

    const { data: storesWithoutStock, loading: loadingWithStock } =
        useSrsStores({
            coordinates: location,
            pageSize: 200,
            filters: {
                pme_store: storeView === 'pme',
                jb_store: storeView === 'justbrands',
                ci_store: storeView === 'castiron',
            },
        })

    const stores = useMemo(() => {
        const results = [...storesWithStock, ...storesWithoutStock].reduce<
            SrsStore[]
        >((acc, item) => {
            if (acc.find((i) => i.storeId === item.storeId)) {
                return acc
            }

            acc.push({ ...item, skuStock: item.skuStock || 0 })
            return acc
        }, [])

        if (!location) {
            results.sort((a, b) => a.name.localeCompare(b.name))
        }

        return results
    }, [storesWithStock, storesWithoutStock, location])

    const isLoading = loading || loadingWithStock

    const header = (
        <HeadingWrapper>
            <Heading variant="h2" element="h2">
                <Trans id="catalog.productDetailView.sizeAvailability.modal.title">
                    Check availability in store
                </Trans>
            </Heading>
            <SubHeading variant="h4" element="h3">
                {product.name}
            </SubHeading>
            <StyledPopperSelect
                label={t({
                    id: 'cart.addToCartForm.selectSize',
                    message: `Select a size`,
                })}
                // isLoading={isLoading}
                items={options}
                onSelectionChange={(key) => {
                    if (setSizeSku) {
                        const option = options.find((o) => o.key === key)

                        setSizeSku(option?.sku)
                    }
                }}
                defaultPopperPosition="top"
                defaultSelectedKey={defaultKey}
                labelIsVisuallyHidden
            >
                {(item) => <Item key={item.key}>{item.label}</Item>}
            </StyledPopperSelect>
        </HeadingWrapper>
    )

    const footer = (
        <CtaBlock>
            <StyledIcon component={InfoCircleIcon} />
            <Trans id="catalog.productDetailView.sizeAvailability.modal.cta">
                Would you like to pick up the item at a store? Contact the store
                by phone to have it set aside for you.
            </Trans>
        </CtaBlock>
    )

    return (
        <ResponsiveModal
            onBack={onClose}
            enableScrollLock
            header={!isDesktop && header}
            footer={!isDesktop && footer}
        >
            <Col>
                {isDesktop && header}
                {isDesktop && <Separator />}
                <StoresWrapper visible={sizeSku !== undefined}>
                    <StyledSearchForm
                        storeLocatorPage={false}
                        searchResults={stores}
                        storeView={storeView}
                        onSelect={setSelectedStore}
                        selectedStore={selectedStore}
                        selectedStoreLocation={selectedStoreLocation}
                        setSelectedStoreLocation={(
                            location: Location | undefined,
                        ) => setSelectedStoreLocation(location)}
                        setCurrentLocation={setCurrentLocation}
                        onClose={() => setSelectedStore(undefined)}
                        resetForm={() => {
                            setSelectedStore(undefined)
                            setSelectedStoreLocation(undefined)
                        }}
                        searchResultsLoading={isLoading}
                    />
                </StoresWrapper>
                <ResultDetailOverlayModal
                    selectedStore={selectedStore}
                    storeView={storeView}
                    onClose={() => setSelectedStore(undefined)}
                />
                {isDesktop && <Separator />}
                {isDesktop && footer}
            </Col>
            <WrapperMap>
                <StoreMap
                    stores={sizeSku ? stores : []}
                    amountResults={sizeSku ? stores.length : 0}
                    location={currentLocation}
                    selectedStoreLocation={
                        sizeSku ? selectedStoreLocation : undefined
                    }
                    selectedStore={sizeSku ? selectedStore : undefined}
                    onSelect={setSelectedStore}
                    storeView={storeView}
                    zoomLevel={{
                        default: 8,
                        noResults: 8,
                    }}
                />
            </WrapperMap>
        </ResponsiveModal>
    )
}

export default ShopSupplyModal
